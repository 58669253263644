import $ from 'jquery';

// Nav toggle mobile
$('.mobile-menu').click(function(){

    $(this).toggleClass('toggled');
    
    if($('.mobile-menu').hasClass('toggled')){
        $('.menu').addClass('menu-open');
    } else {
        $('.menu').removeClass('menu-open');
    }
});