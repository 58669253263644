import $ from 'jquery';

$( () => {

    function scrollToCategories(){

        // Get category parameter 
        let param = window.location.href.split('?')[1];

        // If parameter exists...
        if( param ){

            if( param.length > 0 ){
    
                // Scroll to search section immediately
                $('html, body').animate({
                    scrollTop: $('#more').offset().top
                }, 0);
    
            }
        }
    }

    scrollToCategories();
});