// Smooth scrolling anchors
import $ from 'jquery';

$(() => {

    $('a').on('click', function(e){
        if( this.hash !== "" ){

            var hash = this.hash;

            if( $(hash).length == 0 ){
                window.location.href = hash;
            } else {

                e.preventDefault();

                $('html, body').animate({
                    scrollTop: $(hash).offset().top
                }, 800);
            }
        }
    });
});