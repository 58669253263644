import $ from 'jquery';

// Prevent function spam on scroll check 
export function onScroll(func){

    // Set scrolling to false
    let scrolling = false;
    
    // If scrolling, set scrolling to true
    $( window ).scroll( (func) => {

        scrolling = true;

    });
    
    // Prevent scroll trigger spam with 500ms interval
    setInterval( () => {

        // If scrolling, run function
        if ( scrolling ) {

            scrolling = false;
            
            // Run passed function
            func();

        }
    }, 500 );
}
