import $ from "jquery";

import {
	gsap,
	TweenLite,
	Power2,
	TimelineMax,
	TimelineLite,
	Linear,
	Bounce,
} from "gsap/all";

import { DrawSVGPlugin } from "../lib/gsap-extras/DrawSVGPlugin";

import { inViewport } from "../utilities/inViewport";

import { onScroll } from "../utilities/onScroll";

gsap.registerPlugin(DrawSVGPlugin);

// Default ease
TweenLite.defaultEase = Power2.easeInOut;

// Animation function
function animation() {
	var sWidth = $(window).width();
	// Animate appropriate infographic based on device size
	if (sWidth <= 1023) {
		var device = "mobile";
	} else {
		var device = "desktop";
	}

	const svg = `.infographic div[data-device="${device}"] svg`;

	// If the element is scrolled into view...
	if (inViewport($(svg), 100)) {
		let ele = $(svg);

		// If not already animated...
		if (!$(ele).hasClass("animated")) {
			$(function (anim) {
				const master = new TimelineLite();
				let tlArray = [];
				let delay = 0;

				$(`${svg} .item`).each(function (i) {
					const item = $(`${svg} #item${i + 1}`);
					const duration = 0.5;

					let tl = new TimelineMax({ delay });

					tl.to(
						item,
						duration,
						{
							opacity: 1,
							ease: Linear.easeNone,
						},
						0
					);

					tlArray.push(tl);
					delay += duration;
				});

				$(`${svg} .progressMask`).each(function (i) {
					const item = $(`${svg} #progressMask${i + 1}`);

					// make the different masks animate at the same speed
					const baseSpeed = 0.05;
					const dots = item.data("num-dots") || 4;
					const duration = baseSpeed * dots;

					// if the svg animates from the incorrect starting point apply
					// data-draw-svg-reverse-origin to the element
					const isReversed =
						typeof item.data("draw-svg-reverse-origin") !=
						"undefined";

					let tl = new TimelineMax({ delay });

					tl.fromTo(
						item,
						duration,
						{ drawSVG: isReversed ? "100%" : "0% 100%" },
						{
							drawSVG: isReversed ? "0%" : "100% 100%",
							ease: Linear.easeNone,
						},
						0
					);

					tlArray.push(tl);
					delay += duration;
				});

				master.add(tlArray, 0, "normal");

				// Run animation function
				$((anim) => {
					master.play(0);
				});
			});

			// Add class to element to prevent re-trigger of animation
			$(ele).addClass("animated");
		}
	}
}

onScroll(animation);
