import $ from 'jquery';

import { CountUp } from 'countup.js';

import { inViewport } from '../utilities/inViewport';

import { onScroll } from '../utilities/onScroll';

$( () => {

    // Animate when in viewport
    // Play count animation when in viewport
    function countAnimation(){
        // For each element with data attribute "count"
        $('*[data-anim="count"]').each( function(index, count) {
            // Get current element in view
            let ele = $(this);
            // Get data-anim name from current element
            let anim = $(this).attr('data-anim');
            // If the current element does not have the class matching the data-anim name...
            if( !(ele.hasClass(anim)) ) {
                // If the element is scrolling into view...
                if( inViewport(this) ) {
                    const countUp = new CountUp(count, $(count).data('num'), {
                        duration: 3
                    });
                    
                    if(!countUp.error) {
                        countUp.start();
                    } else {
                        console.error(countUp.error);
                    }
    
                    // Add the animation class name to the element, triggering the animation
                    $(ele).addClass(anim);
                }
            }
        });
    };
    
    // Play function on scroll
    onScroll(countAnimation);

});
