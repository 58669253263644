import $ from "jquery";

// Check if element is in viewport
export function inViewport(elem, offset = 0) {
	// If passed element exists on page...
	if ($(elem).length) {
		// Get top and bottom of window
		let docViewTop = $(window).scrollTop();
		let docViewBottom = docViewTop + $(window).height();

		// Get top and bottom of element
		let elemTop = $(elem).offset().top;
		let elemBottom = elemTop + $(elem).height();

		console.log(elemTop, docViewTop);
		return elemTop - offset <= docViewTop;
	}
}
